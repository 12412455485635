import ApiService from "@/common/api/api.service";
import { TODAS_MATRICULAS } from "@/store/matriculas.module";
import JwtService from "@/common/utils/jwt";
import store from "@/store/Store.js";

const MatriculaService = {
    async getTodasMatriculasUserLogadoAndStore() {
        let data = await ApiService.get(
            `Matricula/matriculas-usuario?id=${JwtService.buscarId()}`
        );
        // BUG ou FEATURE??? Back-end retornando com 3 `datas` aninhadas:
        data = data.data.data;

        store.commit(TODAS_MATRICULAS, data);
        return data;
    },

    async buscarPorId(id) {
        if (!id) id = JwtService.buscarId();
        let data = await ApiService.get(
            `Matricula/matriculas-usuario?id=${id}`
        );
        // BUG ou FEATURE??? Back-end retornando com 3 `datas` aninhadas:
        return data;
    },

    async matriculasUsuario(id) {
        let { data } = await ApiService.get(
            `Matricula/matriculas-usuario/${id}`
        );
        return data;
    },

    async rematricula() {
        let { data } = await ApiService.get(`Matricula/rematricula`);
        return data;
    },

    async buscarMatricula(id) {
        let { data } = await ApiService.get(`Matricula/${id}`);
        return data;
    },

    async buscarTitulosPorMatricula() {
        let { data } = await ApiService.get(
            `Titulo/contas-receber/obter-por-matricula?id=${JwtService.buscarMatricula()}`
        );
        return data.data;
    },
};

export default MatriculaService;
