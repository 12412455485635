<template>
    <topbar
        :titulo="$t('ACADEMICO.MATRICULA_REMATRICULA')"
        :subtitulo="$t('ACADEMICO.REMATRICULA_DESCRICAO')"
        :breadcrumbs="breadcrumbs"
    >
    </topbar>
</template>
<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Components:

export default {
    components: { Topbar },
    props: {
        podeRematricular: { type: Boolean, default: false },
    },
    data() {
        return {
            breadcrumbs: [
                { titulo: this.$t('ACADEMICO.ACADEMICO') },
                { titulo: this.$t('ACADEMICO.MATRICULA_REMATRICULA') },
            ],
        };
    },
    methods: {
        // FUNÇÕES CRUD:
        cadastrar() {
            this.$emit('fazer-rematricula');
        },
    },
};
</script>
