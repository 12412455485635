<template>
    <div>
        <title-rematriculas
            :podeRematricular="tabela.items.length > 0"
            @fazer-rematricula="fazerRematricula"
        />
        <b-table
            head-variant="light"
            striped
            hover
            :fields="tabela.fields"
            :items="tabela.items"
        >
            <template v-slot:cell(turma)="item">
                {{ item.item.turma }} ({{ item.item.anoLetivo }})
            </template>
            <template v-slot:cell(acoes)="item">
                <div class="flex">
                    <b-dropdown variant="outline-default">
                        <b-dropdown-item @click="visualizarMatricula(item)">
                            {{ $t('MATRICULAS.VISUALIZAR_MATRICULA') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="alunosVinculados.length > 0"
                            @click="fazerRematricula(item)"
                        >
                            {{ $t('MATRICULAS.FAZER_REMATRICULA') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="visualizarContratoMatricula(item)"
                        >
                            {{ $t('MATRICULAS.VISUALIZAR_CONTRATO') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>
        </b-table>
    </div>
</template>
<script>
// helpers & utils
import pdf from '@/common/utils/pdf';
import mensagem from '@/common/utils/mensagem';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import { VISUALIZAR_MATRICULA } from '@/store/matriculas.module';
import rotas from '@/common/utils/rotas';
import JwtService from '@/common/utils/jwt';

// services
import ContratoService from '@/common/services/contratos/contratos.service';
import MatriculaService from '@/common/services/matricula/matricula.service';

// components
import TitleRematriculas from './components/TituloRematriculas';
export default {
    components: {
        TitleRematriculas,
    },
    data() {
        return {
            tabela: {
                fields: [
                    {
                        key: 'codigoMatricula',
                        label: 'Código',
                        sortable: true,
                    },
                    {
                        key: 'nomeAluno',
                        label: 'Aluno',
                        sortable: true,
                    },
                    {
                        key: 'turma',
                        label: 'Turma',
                        sortable: true,
                    },
                    {
                        key: 'periodoLetivoDescricao',
                        label: 'Período',
                        sortable: true,
                    },
                    {
                        key: 'idiomaDescricao',
                        label: 'Idioma',
                        sortable: true,
                    },
                    {
                        key: 'acoes',
                        label: 'Ações',
                    },
                ],
                items: [],
                alunosVinculados: [],
            },
        };
    },
    mounted() {
        this.getMatriculas();
        this.getAlunosVinculadosResponsavel();
    },
    methods: {
        getAlunosVinculadosResponsavel() {
            this.alunosVinculados = JwtService.buscarAlunosVinculados().reduce(
                (alunosSemDuplicatas, aluno) => {
                    const existe = alunosSemDuplicatas.some(
                        (a) => a.value === aluno.matriculaId
                    );
                    if (!existe) {
                        alunosSemDuplicatas.push({
                            text: aluno.nome,
                            value: aluno.matriculaId,
                        });
                    }
                    return alunosSemDuplicatas;
                },
                []
            );
        },
        getMatriculas() {
            this.$store.dispatch(START_LOADING);
            MatriculaService.getTodasMatriculasUserLogadoAndStore()
                .then((data) => {
                    this.tabela.items = data;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        verTitulosAberto(item) {
            let id = item.item.id;
            return this.$router.push({ name: 'financeiro', params: { id } });
        },
        visualizarMatricula(item) {
            let matricula = item.item;
            this.$store.commit(
                VISUALIZAR_MATRICULA,
                JSON.parse(JSON.stringify(matricula))
            );
            rotas.irPara('matricula', item.item.id);
        },
        async visualizarContratoMatricula(item) {
            try {
                this.$store.dispatch(START_LOADING);

                const {
                    data: { itens },
                } = await ContratoService.buscarPorMatricula(item.item.id);

                if (itens[0]) {
                    const { data } = await ContratoService.baixarPDF(
                        itens[0].id
                    );

                    if (data.documento) {
                        const bytes = pdf.base64ToArrayBuffer(data.documento);
                        const blob = new Blob([bytes], {
                            type: 'application/pdf',
                        });

                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = data.nomeDocumento;
                        link.click();
                    }
                }
            } catch (err) {
                mensagem.showErrors(err);
            } finally {
                this.$store.dispatch(STOP_LOADING);
            }
        },
        fazerRematricula(item) {
            // instanciando variável local:
            let id = '';
            // se houver apenas uma matrícula:
            // console.log(this.$store.state.matriculas.matriculas)
            // if (this.$store.state.matriculas.matriculas.length == 1) {
            //     id = this.$store.state.matriculas.matriculas[0].id;
            //     return this.$router.push({
            //         name: 'rematricula',
            //         params: { id },
            //     });
            // }
            // se houver mais uma:
            id = item.item.id;
            return this.$router.push({ name: 'rematricula', params: { id } });
        },
    },
};
</script>
